import {
    Factory,
    ColorSwatch as MantineColorSwatch,
    ColorSwatchStylesNames,
    MantineTheme,
    polymorphicFactory,
    StylesApiProps,
    useMantineTheme,
    useProps,
    useStyles,
    MantineSize,
    BoxProps,
} from '@coveord/plasma-mantine';
import {ReactNode} from 'react';
import classes from './StatusToken.module.css';

export type StatusTokenFactory = Factory<{
    props: StatusTokenProps;
    defaultRef: HTMLDivElement;
    defaultComponent: 'div';
    stylesNames: StatusTokenComponentStylesNames;
}>;
export type StatusTokenComponentStylesNames = ColorSwatchStylesNames;
export type StatusTokenVariant = 'info' | 'success' | 'caution' | 'error' | 'disabled' | 'waiting' | 'edited' | 'new';
export type StatusTokenSize = Extract<MantineSize, 'sm' | 'lg'>;

export interface StatusTokenProps extends BoxProps, StylesApiProps<StatusTokenFactory> {
    /**
     * The size of the token.
     */
    size?: StatusTokenSize;
    /**
     * The variant of the token.
     */
    variant?: StatusTokenVariant;
    /**
     * The content rendered inside the token.
     */
    children?: ReactNode;
}

const defaultProps: Partial<StatusTokenProps> = {size: 'lg', variant: 'info'};

const resolveThemeColorFromVariant = (
    variant: StatusTokenVariant,
    size: StatusTokenSize,
    theme: MantineTheme,
): string => {
    switch (variant) {
        case 'success':
            return theme.colors.lime[6];
        case 'caution':
            return size === 'lg' ? theme.colors.warning[6] : theme.colors.yellow[3];
        case 'error':
            return theme.colors.red[6];
        case 'disabled':
            return theme.white;
        case 'waiting':
            return theme.colors.gray[6];
        case 'edited':
            return theme.colors.action[6];
        case 'new':
            return theme.colors.teal[3];
        case 'info':
        default:
            return size === 'lg' ? theme.colors.navy[5] : theme.colors.navy[1];
    }
};
const resolveSize = (size: StatusTokenSize): number => (size === 'sm' ? 4 : 8);

export const StatusToken: ReturnType<typeof polymorphicFactory<StatusTokenFactory>> =
    polymorphicFactory<StatusTokenFactory>((props, ref) => {
        const theme: MantineTheme = useMantineTheme();
        const {
            variant,
            size,
            className,
            vars: _vars,
            style,
            styles,
            classNames,
            children,
            ...others
        } = useProps('StatusToken', defaultProps, props);
        const getStyles = useStyles<StatusTokenFactory>({
            name: 'StatusToken',
            classes,
            className,
            props,
        });
        const color = resolveThemeColorFromVariant(variant, size, theme);

        return (
            <MantineColorSwatch
                size={resolveSize(size)}
                variant={variant}
                color={color}
                ref={ref}
                {...getStyles('root', {
                    className,
                    styles,
                    classNames,
                })}
                __vars={{'--status-token-color': color}}
                {...others}
            >
                {children}
            </MantineColorSwatch>
        );
    });
